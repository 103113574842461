@import "../import.scss";

.actionLink {
  text-decoration: underline;
  color: $brand;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
}
.flex {
  display: flex;
  gap: 24px;
}

.row {
  margin-bottom: 12px;
  &.head {
    font-weight: bold;
    padding-bottom: 4px;
    border-bottom: 1px solid #777;
  }
}

.biodentis {
  .actionLink {
    color: $bio;
  }
}
