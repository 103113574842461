@import "../../import.scss";

.form {
}

.roundButton {
  height: 36px;
  min-height: 36px;
  border: none;
  font-weight: bold;
  font-size: 80%;
  border-radius: 6px;
  padding-left: 20px;
  padding-right: 20px;
  cursor: pointer;
  transition: box-shadow 0.4s, color 0.4s, background-color 0.4s;
  box-shadow: $lightShadow;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #555;
  color: white;
  &:hover {
    opacity: 0.8;
  }
  &.active {
    background-color: $brand;
    color: white;
  }
}

.selector {
  height: 40px;
  cursor: pointer;
  outline: none;
  border: none;
  background: #ebf7f8;
  border-bottom: 1px solid #4c4c68;
  color: #4c4c68;
  width: 180px;
  margin-left: 8px;
  padding-left: 12px;
}

.label {
  font-size: 90%;
  margin-bottom: 8px;
}
.input {
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;

  > input,
  > textarea {
    border-radius: 6px;
    outline: none;
    border: none;
    height: 36px;
    padding-left: 12px;
    padding-right: 12px;
    box-shadow: $lightShadow;
  }
  > textarea {
    padding-top: 12px;
    padding-bottom: 12px;
    min-height: 80px;
  }
}

.fileUpload {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.buttonSelector {
  display: flex;
  > div {
    flex: 1;
  }
}
