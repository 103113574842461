@import "../import.scss";

.loginBox {
  box-shadow: 0px 0px 16px 0px #00000022;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #222;
  height: 30%;
  min-height: 200px;
  min-width: 300px;
  padding: 80px;
  color: white;
  border-radius: 12px;
  font-weight: bold;
  .loginTitle {
    text-align: center;
    margin-bottom: 30px;
  }
}
