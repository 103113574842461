@import "../../import.scss";

.clientForm {
  .buttons {
    display: flex;
    > div {
      flex: 1;
    }
  }
  .uploadSection {
    display: flex;
    margin: 24px;
  }
}
