.laufzettel {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;

  padding: 20px;
  .flex {
    font-size: 200%;
    margin-bottom: 24px;
    flex-wrap: wrap;
  }
  b {
    font-size: 120%;
  }
  .singleStep {
    border: 1px solid black;
    padding: 8px;
    .stepOps {
      font-size: 80%;
      font-weight: bold;
    }
  }
}
.printButton {
}

@media print {
  .header {
    display: none;
  }
  .printButton {
    display: none;
  }
}
