$brand: #039498;
$text: #000028;
$orange: #f35b20;
$ref: #ff7675;
$red: #ff7675;
$green: rgb(34, 153, 34);

$bio: #bdc516;

$lightShadow: 0px 0px 3px 0pc #555;
.flex1 {
  flex: 1;
}

.box {
  box-shadow: $lightShadow;
  padding: 12px;
  border-radius: 6px;
  background-color: white;
  margin-bottom: 12px;
  .title {
    font-weight: bold;
    font-size: 90%;
  }
}

.siteHeader {
  font-weight: bold;
  font-size: 120%;
  margin-top: 12px;
  margin-bottom: 12px;
}
