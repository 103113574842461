@import "../import.scss";

.models {
  display: flex;
}

.clientStep {
  min-height: 100px;
  height: 100px;
  box-shadow: $lightShadow;
  margin-bottom: 12px;
  display: flex;
  border-radius: 8px;
  white-space: pre-wrap;
  border: 4px solid transparent;
  &.done {
  }
  &.aborted {
    background-color: #666;
    opacity: 0.5;
    pointer-events: none;
  }
  &.stepTypeMESSAGE.pending {
    border-color: lighten($green, 0%);
  }
  .buttons {
    display: flex;
    flex: 1;
    align-items: flex-end;
    flex-direction: column;
    justify-content: center;
    margin-right: 18px;
  }
  .actionLinks {
    display: flex;
    flex: 1;
    align-items: flex-end;
    flex-direction: column;
    justify-content: center;
    margin-right: 18px;
  }
  .message {
    flex: 1;
    padding: 12px;
  }

  .styler {
    border-left: 3px dotted #ddd;
    margin-left: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ddd;
    margin-right: 20px;
    > svg {
      position: relative;
      left: -11px;
      font-size: 20px;
    }
  }
  &.current {
    .styler {
      color: $orange;
      border-color: $orange;
    }
  }
  &.storno {
    background-color: red;
  }
  &.done {
    .styler {
      color: green;
      border-color: green;
    }
  }
  .text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .date {
      font-size: 80%;
      font-weight: bold;
    }
  }
}
.stlSelector {
  .row {
    display: flex;
    flex-direction: row;
    padding: 4px;
    .stlSelectorInput {
      background: white;
      border: none;
      outline: none;
      border-radius: 4px;

      box-shadow: $lightShadow;
      flex: 1;
    }
  }
}
