@import "../import.scss";

.taskHolder {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin: 12px;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.currentTask {
  flex: 1;
  padding: 14px;
  border: 14px solid $red;
  white-space: nowrap;
  max-width: 20%;
  width: 100%;
  min-width: 20%;
  background-color: white;
  &.taken {
    border-color: $brand;
  }
  &.done {
    border-color: $green;
  }
}

.clientNumber {
  margin-right: 12px;
  font-weight: bold;
  font-size: 120%;
}
.clientName {
  font-size: 120%;
}

.taskStep {
  font-weight: bold;
}

.biodentis {
  .currentTask {
    &.taken {
      border-color: $bio;
    }
  }
}
