@import "./import.scss";

.App {
  height: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

a.styled {
  color: $orange;
  text-decoration: underline;
}