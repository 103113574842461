@import "../../import.scss";

.header {
  min-height: 88px;
  height: 88px;
  border-bottom: 1px solid $text;
  display: flex;
  align-items: center;
  background-color: #222;
  color: #cdd;

  .logo {
    margin-right: 40px;
    display: flex;
    justify-content: center;
    > img {
      height: 89px;
    }
  }
  .title {
    padding-left: 12px;
  }
}

.biodentis {
  .header {
    background-color: $bio;
  }
  .roundButton {
    &.active {
      background-color: $bio;
    }
  }
}
