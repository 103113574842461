@import "../../import.scss";

.inner {
  padding-bottom: 60px;
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
  .wrapper {
    margin-right: 20px;
    margin-left: 20px;
  }
}
