@import "../../import.scss";

.praxisList {
  .praxis {
    display: flex;
    cursor: pointer;
    &:hover {
      background-color: #eee;
    }
    .name {
      flex: 1;
    }
  }
}
