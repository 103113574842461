@import "../../import.scss";

.gkList {
  .gkItem {
    display: flex;
    .gkName {
      flex: 1;
    }
    .gkPraxen {
      width: 120px;
    }
    .gkClients {
      width: 200px;
    }
  }
}
