@import "../../import.scss";

.calendar {
  .fc-h-event {
    background-color: $orange;
    border: none;
    padding: 2px;
    padding-left: 4px;
    padding-right: 4px;
  }
}
