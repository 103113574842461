@import "../../import.scss";

.clientList {
  .viewButtons {
    display: flex;
  }
}
.clientListItem {
  text-decoration: none;
  color: inherit;
  display: flex;
  box-shadow: $lightShadow;
  padding-bottom: 12px;
  padding-top: 12px;
  padding-left: 12px;
  margin-bottom: 12px;
  height: 30px;
  line-height: 30px;
  font-size: 90%;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.5s;
  &.important {
    background-color: #cec;
  }
  &:hover {
    background-color: #eee;
  }
  &.noClick:hover{
    background-color: transparent;
    cursor: default;
  }
  .clientNum {
    width: 180px;
    font-weight: bold;
  }
  .clientName {
    flex: 1;
    width: 100px;
  }
  .clientStatus {
    width: 300px;
    border: 4px solid grey;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    color: grey;
    border-color: grey;
    &.step0 {
      color: grey;
      border-color: grey;
    }
    &.step1,
    &.step12 {
      color: $orange;
      border-color: $orange;
    }
    &.step2 {
      color: green;
      border-color: green;
    }
  }
  .nextAssignment {
    flex: 1;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 12px;
  }
}
