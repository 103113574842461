@import "../../import.scss";

.nav {
  padding-left: 20px;
  padding-right: 20px;
  margin-right: 20px;
  color: $text;
  font-size: 90%;
  position: relative;

  .userArea {
    height: 40px;
    line-height: 40px;
    cursor: pointer;
    color: white;
    &:hover {
      color: $brand;
    }
  }
  .dropDown {
    position: absolute;
    box-shadow: 1px 1px 3px 1px #00000022;
    background-color: white;

    right: 0;
    z-index: 2;
    min-width: 200px;
    .item {
      display: flex;
      height: 40px;
      line-height: 40px;
      cursor: pointer;
      &:hover {
        background-color: #eee;
        color: $brand;
      }
      .icon {
        width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 120%;
      }
      .text {
        padding-left: 0px;
      }
    }
  }
}

.biodentis {
  .dropDown {
    .item {
      &:hover {
        color: $bio;
      }
    }
  }
  .nav {
    .userArea {
      &:hover {
        color: black;
      }
    }
  }
}
