html {
  margin: 0;
  padding: 0;
  height: 100%;
}
body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: "Inter var", ui-sans-serif, system-ui, -apple-system,
    BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000028;
  font-size: 90%;
}

#root {
  height: 100%;
}
#root > div {
  height: 100%;
  display: flex;
  flex-direction: column;
}
