.attentionItem {
  display: flex;
  padding: 8px;
  border-bottom: 1px solid #ddd;
  .text {
    flex: 1;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }
  .actoin {
    cursor: pointer;
    margin-left: 12px;
    &:hover {
      opacity: 0.8;
      text-decoration: underline;
    }
  }
}

.outstandingTask {
  display: flex;
  padding: 4px;
  &.inProgress {
    background-color: aquamarine;
  }
  border-bottom: 1px solid #ddd;
  .type {
    flex: 1;
  }
  .kunde {
    flex: 1;
  }

  .gk {
    flex: 1;
  }

  .patient {
    flex: 1;
  }

  .step {
    flex: 1;
  }
}
